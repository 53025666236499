.auth-form-container,
.login-form,
.register-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Caveat', Arial, Helvetica, sans-serif;
    font-size: 1.5rem;
    font-weight: bolder;
}

.auth-form-container-register {
    position: absolute;
    align-items: center;
    justify-content: center;
    left: 36.4%;
    top: 0%;
    width: 500px;
    height: 100%;
    color: white;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 0) 100%);
    border-radius: 20px;
    padding: 20px;
    overflow: hidden;
    a {
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        color: white;
        font-size: medium;
        font-weight: lighter;
        font-family: 'Caveat', Arial, Helvetica, sans-serif;
    }
    a:hover {
        color: #0426c0;
    }
    .tos-checkbox-container {
        position: relative;
        left: -30%;
        display: flex;              // Use flexbox to align items horizontally
        align-items: center;
        margin-top: 15px;
        margin-bottom: 15px;
        font-size: 14px;
    
        input[type="checkbox"] {
            transform: scale(0.8);  // Adjust size of the checkbox
            margin-right: -40%;     // Reduce margin to the right of the checkbox
        }
    
        .tos-link {
            color: blue;
            cursor: pointer;
            text-decoration: underline;
            font-size: 14px;       // Adjust font size of the link
            margin-left: 0px;      // Reduce margin to the left of the text
    
            &:hover {
                text-decoration: none;
            }
        }
    }
    button {
        margin-bottom: 20px;
    }
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the modal appears above other elements */
    pointer-events: auto; /* Enable pointer events for the modal */
}

.modal-content {
    background-color: #202020;
    padding: 20px;
    border-radius: 8px;
    max-width: 80%; /* Adjust as needed */
    max-height: 80%; /* Adjust as needed */
    overflow-y: auto; /* Enable scrolling if content exceeds modal height */
    pointer-events: auto; /* Enable pointer events for modal content */
    h1 {
        font-size: 1.7rem;
        font-family: 'Caveat', Arial, Helvetica, sans-serif;
        border-bottom: 1px solid white;
        text-align: center;
    }
    li {
        list-style-type: none;
        border-bottom: 1px solid white;
    }
    p {
        font-size: 1rem;
        font-family: 'Caveat', Arial, Helvetica, sans-serif;
        color:rgb(185, 185, 185);
    }
    h2 {
        font-size: 1.5rem;
        font-family: 'Caveat', Arial, Helvetica, sans-serif;
        color:rgb(223, 222, 222);
    }
}

.auth-form-container {
    position: absolute;
    align-items: center;
    justify-content: center;
    left: 24.5%;
    top: 25%;
    width: 50%;
    height: 400px;
    color: white;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 0) 100%);
    border-radius: 20px;
    padding: 20px;
    overflow: hidden;
    a {
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        color: white;
        font-size: medium;
        font-weight: lighter;
    }
    a:hover {
        color: #0426c0;
    }
    button {
        margin-bottom: 20px;
    }
}

label {
    color: white;
    font-size: 1.2rem;
    margin-bottom: 5px;
    font-family: 'Caveat', Arial, Helvetica, sans-serif;
}

input {
    border: none;
    height: 30px;
    width: 500px;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(46, 47, 94, 0.9) 50%, rgba(46, 47, 94, 0.9) 50%, rgba(0, 0, 0, 0) 100%);
    margin: 0 auto;
    padding: 10px;
    color: black;
    margin-bottom: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.9);
    border-bottom: 1px solid rgba(0, 0, 0, 0.9);
    font-family: 'Caveat', Arial, Helvetica, sans-serif;
    font-size: 1.2rem;
    text-align: center;
}

input:focus {
    color: rgb(255, 255, 255);
    font-family: 'Caveat', Arial, Helvetica, sans-serif;
    font-size: 1.2rem;
    color: black;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 50%, rgba(0, 0, 0, 0) 100%);
    outline: none;
}

.Login-btn {
    margin: 0 auto;
    border: none;
    background-color: #4CAF50;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
}

.submit-btn:hover {
    background-color: #45a049;
}

.link-btn {
    background: none;
    color: white;
    text-decoration: underline;
}

.Errors {
    color: red;
    font-size: 1.2rem;
    font-family: 'Caveat', Arial, Helvetica, sans-serif;
}

.Success {
    color: green;
    font-size: 1.2rem;
    font-family: 'Caveat', Arial, Helvetica, sans-serif;
}