.sum {
    display: flex;
    position: fixed;
    bottom: 1%;
    left: 36%;
    height: 6%;
    justify-content: space-between;
    align-items: center;
    background-color:rgba(50, 50, 50, 0.4);
    background-image: url(./assets/button.png);
    z-index: 1;
    font-size: 20px;
    font-weight: bolder;

    .item {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        left: -3%;
        height: 100%;
        padding: 0 1rem;
        color: white;
        -webkit-text-stroke:1px black;
        -webkit-text-fill-color: white;
        .ul {
            list-style: none;
            display: flex;
            margin: 25px;
            li {
                list-style: none;
                display: flex;
                margin: 0 1rem;
                font-size: 40px;
                font-weight: bolder;
                cursor: pointer;
                a{
                    list-style: none;
                    color: white;
                    -webkit-text-stroke:1px black;
                    -webkit-text-fill-color: white;
                }
                a:hover{
                    color: #0400fc;
                    -webkit-text-stroke:1px black;
                    -webkit-text-fill-color: #0044ff;
                }
            }
        }
    }
}