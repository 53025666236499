.MethodsExplained {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 30px;
    color: rgb(66, 132, 255);
    font-weight: bolder;
}

.method-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; /* Four columns */
    position: relative;
    margin-top: 20px;
    width: 100%;
    grid-gap: 10px;
    justify-items: center;
    align-items: center;
    color: white;
    list-style-type: none;
    text-align: center;
    h3 {
        color: rgb(66, 132, 255);
    }
    li {
        background-color:rgba(63, 63, 63, 0.2);
        width: 90%;
        font-size: 1.5rem;
    }
}

.Contact{
    // put it on the bottom of the page
    position: absolute;
    top: 2%;
    right: -1%;
    width: 30%;
    height: 15%;
    background-color: rgba(63, 63, 63, 0.4);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(63, 63, 63, 0.4);
    z-index: 1;
    color:aliceblue;
    padding: 1rem;
    // put text in the middle
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1{
        font-family: Arial, Helvetica, sans-serif;
        font-size: 30px;
        color: rgb(66, 132, 255);
        font-weight: bolder;
    }
    p{
        font-family: Arial, Helvetica, sans-serif;
        font-size: 20px;
        margin: 0;
    }
    a{
        color: rgb(66, 132, 255);
        font-size: 50px;
        margin: 20px;
        font-weight: bolder;
    }
}
.PaymentDetails {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.5rem;
    text-align: left;
    background-color: rgba(63, 63, 63, 0.4);
    margin: 20px;
    li {
        margin: 20px 50px;
        color: white;
    }
}