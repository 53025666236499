.Shop{
    display: flex;
    position: fixed;
    // center it
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 80%;
    width: 80%;
    justify-content: space-evenly;
    align-items: center;
    z-index: 1;
    font-size: 20px;
    font-weight: bolder;
    .subscriptions {
        // make a box around
        border: 1px solid black;
        border-radius: 10px;
        background-color:rgba(63, 63, 63, 0.4);
        box-shadow: 0 0 10px rgba(63, 63, 63, 0.4);
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 23%;
        height: 80%;
        padding: 0 1rem;
        color: white;

        h2{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            top: -50%;
            left: 15%;
            color: rgb(25, 76, 241);
            text-shadow: 0 0 10px rgba(63, 63, 63, 0.4);
            font-family: Arial, Helvetica, sans-serif;
            font-size: 50px;
        }
        p{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0%;
            left: -50%;
            color: rgb(255, 255, 255);
            text-shadow: 0 0 10px rgba(63, 63, 63, 0.4);
            font-family: Arial, Helvetica, sans-serif;
            font-size: 30px;     
        }

    }
    .points {
        // make a box around
        border: 1px solid black;
        border-radius: 10px;
        background-color:rgba(63, 63, 63, 0.4);
        box-shadow: 0 0 10px rgba(63, 63, 63, 0.4);
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 23%;
        height: 80%;
        padding: 0 1rem;
        color: white;

        h2{
            position: relative;
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            top: -50%;
            left: -10%;
            color: rgb(25, 76, 241);
            text-shadow: 0 0 10px rgba(63, 63, 63, 0.4);
            font-family: Arial, Helvetica, sans-serif;
            font-size: 50px;
        }
        p{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0%;
            left: -20%;
            color: rgb(255, 255, 255);
            text-shadow: 0 0 10px rgba(63, 63, 63, 0.4);
            font-family: Arial, Helvetica, sans-serif;
            font-size: 30px;    
        }
    }
    .scanners {
        // make a box around
        border: 1px solid black;
        border-radius: 10px;
        background-color:rgba(63, 63, 63, 0.4);
        box-shadow: 0 0 10px rgba(63, 63, 63, 0.4);
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 23%;
        height: 80%;
        padding: 0 1rem;
        color: white;

        h2{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            top: -50%;
            left: -12%;
            color: rgb(25, 76, 241);
            text-shadow: 0 0 10px rgba(63, 63, 63, 0.4);
            font-family: Arial, Helvetica, sans-serif;
            font-size: 50px;
        }
        p{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0%;
            left: -30%;
            color: rgb(255, 255, 255);
            text-shadow: 0 0 10px rgba(63, 63, 63, 0.4);
            font-family: Arial, Helvetica, sans-serif;
            font-size: 30px;    
        }

    }
}
.subscriptions:hover{
    background-color: rgba(63, 63, 63, 0.4);
    box-shadow: 0 0 50px rgb(95, 179, 248);
}
.points:hover{
    background-color: rgba(63, 63, 63, 0.4);
    box-shadow: 0 0 50px rgb(95, 179, 248);
}
.scanners:hover{
    background-color: rgba(63, 63, 63, 0.4);
    box-shadow: 0 0 50px rgb(95, 179, 248);
}