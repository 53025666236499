.Points{
    position: absolute; 
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80%;
    margin-left: -5%;
}

.details {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -80%;
    margin-left: 50%;
    left: 0;
}

.pointplan{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-bottom: 20%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1rem;
    color:rgb(52, 100, 202);
    text-shadow: 0 0 5px rgba(99, 99, 99, 0.4);
}

.pointplan:hover{
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}