body {
    overflow-x: hidden;
}
.data-bar{
    // put it on top of the page
    // transform: skew(-10deg);
    position: absolute;
    display: flex;
    top: 0;
    left: 10%;
    bottom: 0;
    width: 80%;
    height: 13%;
    justify-content: space-between;
    align-items: center;
    background-color:rgba(50, 50, 50, 0.4);
    background-image: url(./assets/button.png);
    z-index: 1;
    color:aliceblue;

    .data-item{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 0 1rem;
        color: white;
        border-left:rgba(0,0,0, 1) 2px solid;
        h3{
            justify-content: center;
            font-size: 12px;
        }
        h1{
            position: relative;
            left: 30%;
            color: crimson;
        }
    }
    animation: data-bar 0.5s ease-in-out forwards;
    @keyframes data-bar {
        0% {
            transform: translateY(-100%);
        }
        75% {
            transform: translateY(15%);
        }
        100% {
            transform: translateY(0);
        }
    }

}

.logo {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    // center it
    left: 25%;
    top: 15%;
    transform: translate(-50%, -50%);
    bottom: 0;
    width: 50%;
    height: 50%;
    z-index: 1;
    font-family: Arial, Helvetica, sans-serif;
    color:aliceblue;
    p{
        font-size: 30px;
        font-weight: bolder;
        text-shadow: 0 0 10px rgba(63, 63, 63, 0.4);
        // put it under the logo
        position: absolute;
        bottom: 25%;
    }
    h1{
        font-size: 20px;
        font-weight: bolder;
        text-shadow: 0 0 10px rgba(63, 63, 63, 0.4);
    }
    // make logo bigger after 1 second
    animation: logo 0.5s ease-in-out forwards;
    @keyframes logo {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }
}

.planet1 {
    // put it in left
    position:absolute;
    left: 60%;
    top: -20%;
    transform: translate(-50%, -50%);
    z-index: 1;
    img{
        width: 2000px;
        height: 1440px;
    }
    animation: planet1 0.5s ease-in-out forwards;
    @keyframes planet1 {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }
}