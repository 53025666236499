.account-details-window{
    position: absolute;
    align-items: center;
    justify-content: center;
    left: 24%;
    width: 50%;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(68, 68, 68, 0.6) 50%, rgba(70, 70, 70, 0.6) 50%, rgba(0, 0, 0, 0) 100%);
    border-radius: 20px;
    padding: 20px;
    overflow: hidden;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    .Title{
        font-size: 30px;
        font-weight: bolder;
        color: white;
        margin-bottom: 20px;
    }
    h2{
        font-size: 20px;
        font-weight: bolder;
        color: white;
        margin-bottom: 20px;
        // make a transparent box around the text
        background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(22, 32, 92, 0.6) 50%, rgba(22, 32, 92, 0.6) 50%, rgba(0, 0, 0, 0) 100%);
        border-radius: 20px;
        padding: 20px;
        overflow: hidden;
        text-align: center;
    }
    .username{
        font-size: 30px;
        font-weight: bolder;
        color: rgb(226, 51, 51);
        margin-bottom: 20px;
        // make a transparent box around the text
        background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(22, 32, 92, 0.6) 50%, rgba(22, 32, 92, 0.6) 50%, rgba(0, 0, 0, 0) 100%);
        border-radius: 20px;
        padding: 20px;
        overflow: hidden;
        text-align: center;
        font-family: Arial, Helvetica, sans-serif;
    }
}

.Subscription{
    position: absolute;
    align-items: center;
    justify-content: center;
    top: 1%;
    left: -2%;
    width: 500px;
    height: 40%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 0) 100%);
    border-radius: 20px;
    padding: 20px;
    overflow: hidden;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    .Title{
        font-size: 30px;
        font-weight: bolder;
        color: rgb(34, 38, 231);
        margin-bottom: 20px;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(129, 129, 129, 0.6) 50%, rgba(128, 128, 128, 0.6) 50%, rgba(0, 0, 0, 0) 100%);
        border-radius: 20px;
        padding: 20px;
    }
    h2{
        font-size: 20px;
        font-weight: bolder;
        color: white;
        margin-bottom: 20px;
        // make a transparent box around the text
        overflow: hidden;
        text-align: center;
    }
}

.Boots{
    position: absolute;
    align-items: center;
    justify-content: center;
    top: 1%;
    right: -2%;
    width: 500px;
    height: 40%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(66, 66, 66, 0.6) 50%, rgba(141, 140, 140, 0.6) 50%, rgba(0, 0, 0, 0) 100%);
    border-radius: 20px;
    padding: 20px;
    overflow: hidden;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    .Title{
        font-size: 30px;
        font-weight: bolder;
        color: rgb(34, 38, 231);
        margin-bottom: 20px;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0.6) 50%, rgba(0, 0, 0, 0) 100%);
        border-radius: 20px;
        padding: 20px;
    }
    h2{
        font-size: 20px;
        font-weight: bolder;
        color: white;
        margin-bottom: 20px;
        // make a transparent box around the text
        overflow: hidden;
        text-align: center;
    }
    h3{
        font-size: 20px;
        font-weight: bolder;
        color: rgb(0, 255, 42);
        margin-bottom: 20px;
        // make a transparent box around the text
        overflow: hidden;
        text-align: center;
    }
}

.change-email-button {
    margin: 0 auto;
    border: none;
    background-color: #4CAF50;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
}
.change-email-button:hover {
    background-color: #45a049;
}

.change-password-button {
    margin: 0 auto;
    border: none;
    background-color: #4CAF50;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
}
.change-password-button:hover {
    background-color: #45a049;
}

button {
    // make the button skew
    transform: skew(-10deg);
    margin: 0 auto;
    border: none;
    padding: 10px 20px;
    border-radius: 0 !important;
    cursor: pointer;
}
button:hover {
    background-color: #45a049;
}

