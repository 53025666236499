.Panels {
    display: flex;
    position: fixed;
    // center it
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 80%;
    width: 80%;
    justify-content: space-evenly;
    align-items: center;
    z-index: 1;
    font-size: 20px;
    font-weight: bolder;
    .Gaming {
        // make a box around
        border: 1px solid black;
        background-color: rgba(50, 50, 50, 0.4);
        background-image: url(./assets/button.png);
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 23%;
        height: 80%;
        padding: 0 1rem;
        color: white;
        h2 {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            top: -50%;
            left: 0%;
            color: rgb(25, 76, 241);
            text-shadow: 0 0 10px rgba(63, 63, 63, 0.4);
            font-family: cursive;
            font-size: 50px;
        }
        ul {
            list-style: none;
            li {
                position: relative;
                margin: 0 1rem;
                width: 200%;
                font-size: 30px;
                left: -130%;
                font-weight: bolder;
                // space between the list items
                margin-bottom: 40px;
            }
        }
        animation: move-right 1.1s ease-in-out forwards;
        @keyframes move-right {
            0% {
                transform: translateX(-400%);
            }
            100% {
                transform: translateX(0), skewX(10deg);
            }
        }
    }
    .web {
        // make a box around
        border: 1px solid black;
        background-color: rgba(50, 50, 50, 0.4);
        background-image: url(./assets/button.png);
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 23%;
        height: 80%;
        padding: 0 1rem;
        color: white;
        h2 {
            position: relative;
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            top: -50%;
            left: 34%;
            color: rgb(25, 76, 241);
            text-shadow: 0 0 10px rgba(63, 63, 63, 0.4);
            font-family: cursive;
            font-size: 50px;
        }
        ul {
            list-style: none;
            li {
                position: relative;
                margin: 0 1rem;
                width: 200%;
                font-size: 30px;
                left: -95%;
                font-weight: bolder;
                margin-bottom: 40px;
            }
        }
        animation: move-right 0.9s ease-in-out forwards;
        @keyframes move-right {
            0% {
                transform: translateX(-400%);
            }
            100% {
                transform: translateX(0), skewX(10deg);
            }
        }
    }
    .Layer4 {
        // make a box around
        border: 1px solid black;
        background-color: rgba(50, 50, 50, 0.4);
        background-image: url(./assets/button.png);
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 23%;
        height: 80%;
        padding: 0 1rem;
        color: white;
        h2 {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            top: -50%;
            left: 0%;
            color: rgb(25, 76, 241);
            text-shadow: 0 0 10px rgba(63, 63, 63, 0.4);
            font-family: cursive;
            font-size: 50px;
        }
        ul {
            list-style: none;
            li {
                position: relative;
                margin: 0 1rem;
                width: 200%;
                font-size: 30px;
                left: -120%;
                font-weight: bolder;
                // space between the list items
                margin-bottom: 20px;
            }
        }
        animation: move-right 0.7s ease-in-out forwards;
        @keyframes move-right {
            0% {
                transform: translateX(-400%);
            }
            100% {
                transform: translateX(0), skewX(10deg);
            }
        }
    }
    .Proxied {
        // make a box around
        top: -2%;
        border: 1px solid black;
        background-color: rgba(50, 50, 50, 0.4);
        background-image: url(./assets/button.png);
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 23%;
        height: 80%;
        padding: 0 1rem;
        color: white;
        .new{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            left: 50%;
            color: rgb(75, 241, 25) !important;
            text-shadow: 0 0 10px rgba(63, 63, 63, 0.4);
            font-family: cursive;
            font-size: 50px;
        }
        h1 {
            position: absolute;
            top: 10%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: rgb(241, 25, 25);
            text-shadow: 0 0 10px rgba(63, 63, 63, 0.4);
            font-family: Arial, Helvetica, sans-serif;
            font-size: 1rem;
        }
        h2 {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            top: -50%;
            left: 25%;
            color: rgb(25, 76, 241);
            text-shadow: 0 0 10px rgba(63, 63, 63, 0.4);
            font-family: cursive;
            font-size: 50px;
        }
        ul {
            list-style: none;
            li {
                position: relative;
                margin: 0 1rem;
                width: 200%;
                font-size: 30px;
                left: -160%;
                font-weight: bolder;
                // space between the list items
                margin-bottom: 20px;
            }
        }
        animation: move-right 0.5s ease-in-out forwards;
        @keyframes move-right {
            0% {
                transform: translateX(-400%);
            }
            100% {
                transform: translateX(0), skewX(10deg);
            }
        }
    }

}

.Proxied:hover {
    background-color: rgba(63, 63, 63, 0.5);
    box-shadow: 0 0 50px rgb(247, 195, 153);
}

.Gaming:hover {
    background-color: rgba(63, 63, 63, 0.5);
    box-shadow: 0 0 50px rgb(247, 195, 153);
}

.web:hover {
    background-color: rgba(63, 63, 63, 0.5);
    box-shadow: 0 0 50px rgb(247, 195, 153);
}

.Layer4:hover {
    background-color: rgba(63, 63, 63, 0.5);
    box-shadow: 0 0 50px rgb(247, 195, 153);
}

.Gaming,
.Layer4,
.web,
.Proxied {
    transition: all 0.3s ease-in-out;
    margin-bottom: 30px;
}

.claimer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10%;
    background-color: rgba(50, 50, 50, 0.4);
    background-image: url(./assets/button.png);
    box-shadow: 0 0 10px rgba(63, 63, 63, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 20px;
    font-weight: bolder;
    text-shadow: 0 0 10px rgba(63, 63, 63, 0.4);
    font-family: Arial, Helvetica, sans-serif;
}