.Subscriptions{
    position: relative; 
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 7%;
    width: 90%;
    height: 100%;
    flex-direction: row;
}

.normal, .premium {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 10px;
    width: 50%;
    height: 100%;
    z-index: 1;

}

.disclaimer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10%;
    margin-top: 20px;
    color: rgb(52, 100, 202);
    text-shadow: 0 0 10px rgba(63, 63, 63, 0.4);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: bold;
    // make a box around it
    // border: 1px solid black;
    border-radius: 10px;
    background-color:rgba(63, 63, 63, 0.4);
}

.plans {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    position: relative;
    margin-left: 7%;
    margin-top: 20px;
    width: 100%;
    height: 100%;
    grid-gap: 10px;
    justify-items: center;
    align-items: center;
}

.plan {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: -7%;
    width: 90%;
    height: 90%;
    text-align: center;
    justify-content: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1rem;
    align-items: center;
}

.cover img{
    width: 105%;
    height: 105%;
}

.plan .cover{
    font-size: 18rem;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color:rgb(52, 100, 202);
    text-shadow: 0 0 5px rgba(99, 99, 99, 0.4);
}

.plan .cover-p{
    font-size: 18rem;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color:rgb(202, 179, 52);
    // make it glow
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.4);
}

.plan ul, .plan p, .plan h1, .plan li {
    font-weight: bold;
    position: relative;
    color: white;
    margin-bottom: 5px;
    left: 0%;
    font-size: 1rem; /* Adjust the font size as needed for these elements */
}

.plan h1 {
    font-size: 1.5rem;
    color: rgb(66, 132, 255);
    margin-bottom: 10px;
    font-weight: bold;
}

.plan p {
    font-size: 1rem;
    color: rgb(147, 255, 133);
    background-color:rgba(63, 63, 63, 0.4)
}

.plan ul {
    position: relative;
    min-width: 40%;
    top: -9%;
    left: -51%;
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
}

.plan li {
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.37);
}

.plan:hover {
    transform: scale(1.1);
    transition: transform 0.5s ease-in-out;
    z-index: 2;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.4);
    cursor: pointer;
}