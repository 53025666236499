.Gaming-Panel,
.Layer4-Panel,
.Layer7-Panel,
.Proxied-Panel {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    width: 90%;
    .attacks-list {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
        top: 0;
        right: -10%;
        background: rgba( 255, 255, 255, 0.25);
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37);
        backdrop-filter: blur( 3.5px);
        -webkit-backdrop-filter: blur( 3.5px);
        border-radius: 10px;
        border: 1px solid rgba( 255, 255, 255, 0.18);
        min-width: 20%;
        h1 {
            position: relative;
            display: flex;
            color: rgba(18, 101, 255, 1);
            font-family: Arial, Helvetica, sans-serif;
            font-size: 1rem;
            font-weight: bold;
        }
        .attack {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            color: rgb(39, 201, 39);
            font-family: Arial, Helvetica, sans-serif;
            font-size: 0.7rem;
            font-weight: bold;
            background-color: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 0) 100%);
            border-top: 1px solid rgba(0, 0, 0, 0.9);
        }
    }
    .forms {
        // make a box around
        position: relative;
        display: flex;
        top: 7%;
        left: 0%;
        border: 1px solid black;
        border-radius: 10px;
        background-color: rgba(50, 50, 50, 0.4);
        background-image: url(./assets/button.png);
        box-shadow: 0 0 10px rgba(63, 63, 63, 0.4);
        justify-content: center;
        align-items: center;
        width: 90%;
        padding: 0 1rem;
        color: white;
        input {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 0;
            font-size: 20px;
            font-weight: bolder;
            background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 50%, rgba(0, 0, 0, 0.9) 50%, rgba(0, 0, 0, 0) 100%);
            border-top: 1px solid rgba(0, 0, 0, 0.9);
            border-bottom: 1px solid rgba(0, 0, 0, 0.9);
            font-family: 'Caveat', Arial, Helvetica, sans-serif;
            box-shadow: 0 0 10px rgba(63, 63, 63, 0.4);
            margin-top: 10px;
            margin-right: 10px;
            margin-bottom: 10px;
            color: rgb(218, 218, 218);
        }
        input:hover {
            background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(18, 101, 255, 1) 50%, rgba(18, 101, 255, 1) 50%, rgba(0, 0, 0, 0) 100%);
            box-shadow: 0 0 30px rgba(71, 206, 240, 0.5);
        }
        input:focus {
            background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(18, 101, 255, 1) 50%, rgba(18, 101, 255, 1) 50%, rgba(0, 0, 0, 0) 100%);
            box-shadow: 0 0 30px rgba(71, 206, 240, 0.5);
        }
        .custom-select:hover {
            background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 50%, rgba(0, 0, 0, 0) 100%);
            box-shadow: 0 0 30px rgba(255, 255, 255, 0.5);
            outline: none;
        }
        .custom-select:focus {
            outline: none;
        }
        .removeField {
            width: 5%;
            height: 2%;
            position: relative;
            margin-top: 3px;
            margin-left: 10px;
            top: 20%;
            background-color: rgb(190, 53, 53);
            border-radius: 20px;
            font-weight: bolder;
            font-family: Arial, Helvetica, sans-serif;
            text-align: left;
        }
        .removeField:hover {
            background-color: rgb(255, 0, 0);
        }
    }
    .custom-select {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        width: 200px;
        height: 30px;
        font-size: 20px;
        font-weight: lighter;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 50%, rgba(0, 0, 0, 0) 100%);
        border-top: 1px solid rgba(0, 0, 0, 0.9);
        border-bottom: 1px solid rgba(0, 0, 0, 0.9);
        box-shadow: 0 0 10px rgba(63, 63, 63, 0.4);
        margin-top: 10px;
        margin-bottom: 10px;
        color: rgb(0, 0, 0);
        text-align: center;
    }
    .addField {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 10%;
        height: 10%;
        background-color: rgb(49, 158, 49);
        border-radius: 20px;
        margin-top: 15px;
        left: -5%;
        font-weight: bolder;
        font-family: Arial, Helvetica, sans-serif;
    }
    .addField:hover {
        background-color: rgb(0, 255, 0);
    }
    .submit {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 10%;
        height: 20%;
        top: 40%;
        margin-top: 15px;
        left: -5.5%;
        background-color: rgba(22, 35, 223, 0.6);
        box-shadow: 0 0 10px rgba(63, 63, 63, 0.4);
        border-radius: 40px;
        color: rgb(0, 0, 0);
        font-size: 30px;
    }
    .submit:hover {
        background-color: rgba(57, 116, 243, 0.8);
        box-shadow: 0 0 30px rgba(255, 255, 255, 0.5);
        color: aliceblue;
    }
    .Errors {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 5%;
        margin-bottom: 50px;
        color: rgb(255, 0, 0);
        font-family: Arial, Helvetica, sans-serif;
    }
}

.ProxyList {
    // fix it in the bottom
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    bottom: 0;
    left: 55%;
    top: 200%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 150%;
    font-size: 1.5rem;

    textarea {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: rgba(50, 50, 50, 0.4);
        background-image: url(./assets/button.png);
        box-shadow: 0 0 10px rgba(63, 63, 63, 0.4);
        border-radius: 10px;
        color: white;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bolder;
        font-size: .8rem;
    }
    input {
        background-color: rgba(50, 50, 50, 0.4);
        margin: 0 !important;
    }
    p {
        margin: 0 !important;
        color : white;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
        font-size: .8rem;
        background-color: rgba(50, 50, 50, 0.4);
    }
    button {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40%;
        height: 20%;
        background-color: rgba(22, 35, 223, 0.6);
        box-shadow: 0 0 10px rgba(63, 63, 63, 0.4);
        color: rgb(0, 0, 0);
        font-size: 30px;
        font-weight: bolder;
        margin-top: 5px;
    }
    button:hover {
        background-color: rgba(57, 116, 243, 0.8);
        box-shadow: 0 0 30px rgba(255, 255, 255, 0.5);
        color: aliceblue;
    }
    ::placeholder {
        color: white;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
        font-size: 1rem;
    }
}